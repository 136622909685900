import Router from 'next/router';
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import subscribeToEvents from 'functions/tracking/eventSubscriber';
import { onPageEvent, onPageLoad } from 'functions/tracking/eventTracker';
import { getPageDetails } from 'functions/tracking/pageDetails';

export const Tracker = props => {
  const isBrowser = typeof window !== 'undefined';
  const getCurrentPageDetails = () => {
    const { pathname } = Router;
    return getPageDetails('Profile', pathname);
  };

  useEffect(() => {
    if (isBrowser) {
      onPageLoad(getCurrentPageDetails);

      const trackedElements = [
        'edit-profile-picture',
        'edit-public-profile',
        'save-public-profile',
        'edit-about-me',
        'save-about-me',
        'edit-personal-details',
        'save-personal-details',
        'add-certificate',
        'edit-certificate',
        'save-certificate',
        'delete-certificate',
        'add-role',
        'edit-role',
        'save-role',
        'delete-role',
        'certifications-private',
        'certifications-public',
        'employmentHistories-public',
        'employmentHistories-private',
        'cloudTranscripts-public',
        'cloudTranscripts-private',
        'upgrade-navbar'
      ];

      subscribeToEvents(onPageEvent, trackedElements, getCurrentPageDetails, {
        click: null
      })();
    }
  }, []);

  useEffect(() => {
    if (isBrowser) {
      Router.onRouteChangeComplete = () => {
        onPageLoad(getCurrentPageDetails);
      };
    }
  });

  return <>{props.children}</>;
};

Tracker.propTypes = {
  children: PropTypes.node
};
